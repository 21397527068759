<template>
  <div class="home-container">
    <p class="tagline">Under Construction</p>
  </div>
</template>

<script>
export default {
  name: 'Portfolio',
};
</script>
