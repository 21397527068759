<template>
  <div class="home-container">
    <p class="tagline">Under Construction</p>
  </div>
</template>

<script>
export default {
  name: 'Home',
};
</script>

<style>
.home-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.tagline {
  font-size: 1.5rem;
  color: #6555b5;
  margin-bottom: 2rem;
  text-align: center;
}

h1 {
  text-align: center;
  margin-bottom: 1rem;
}

h2 {
  margin-top: 2rem;
  color: #6555b5;
}

p {
  line-height: 1.6;
  margin-bottom: 1rem;
}

ul {
  list-style-type: disc;
  margin-left: 1.5rem;
}

li {
  margin-bottom: 1rem;
}

a {
  color: #6555b5;
  text-decoration: underline;
}

strong {
  color: #ffffff;
}

@media (max-width: 600px) {
  .home-container {
    padding: 1rem;
  }

  .tagline {
    font-size: 1.2rem;
  }
}
</style>
